import React, { useState } from 'react';

// ANTD
import { Button, Form } from 'antd';
import Input from 'antd/es/input/Input';

// SERVICES
import { subscribeEmail } from './services';

// STYLES
import './App.scss';

function App() {

  const [form] = Form.useForm();

  const [loader, setLoader] = useState<boolean>(false);

  const subscribe = async(): Promise<void> => {
    try {

      setLoader(true);

        const subscribePayLoad = {
            name: form.getFieldValue('name'),
            email: form.getFieldValue('email')
        };

        const subscribed = await subscribeEmail(subscribePayLoad);

        setLoader(false);

    } catch (error) {
        
    }
};

  return (
    <>
      <article>
          <img src="../assets/img/buy-today-the-wines-of-tomorrow.png" alt="Buy today the wines of tomorrow"/>
          <h1>
              Buy today<br/>the best Wines of tomorrow
          </h1>
          <p>Purchase top premium wines with huge upside potential based in the recommendations of the sommeliers of our community.</p>
          <p>Our <a href="https://lessommeliers.net/">wine platform</a> powered by Blockchain technology, allows you to buy and sell wine directly from producers, trusted dealers or other wine collectors registered in our platform.</p>
          <Form form={form} className="send-email-form-container">
            <Form.Item name="email" rules={[{ pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "This is not an email!" }]}>
              <Input placeholder="email@email.com"/>
            </Form.Item>
            <Form.Item shouldUpdate>
            {() => (
              <Button disabled={form.getFieldError('email').length > 0 || form.getFieldValue('email') == undefined}  onClick={() => subscribe()} loading={loader}>Leave us your email</Button>  
            )}
            </Form.Item>
          </Form>
          <ul>
              <li>
                  <span className="material-symbols-outlined">check</span>
                  <h2>Directly from wine producers or trusted wine dealers to ensure the best quality and condition.</h2>
              </li>
              <li>
                  <span className="material-symbols-outlined">search</span>
                  <h2>Selection from the best chateaux and cellars of different parts of the world.</h2>
              </li>
              <li>
                  <span className="material-symbols-outlined">encrypted</span>
                  <h2>Wine tradeability, authenticity and traceability powered by Blockchain Tecnology.</h2>
              </li>
          </ul>
      </article>
      <article>
          <div className="mentions-container">
              <div className="mention-item">
                  <h3>"Investing in fine wine is more lucrative than ever"</h3>
                  <span className="source">Larissa Zimberoff, Bloomberg - <a href="https://www.bloomberg.com/news/articles/2018-07-19/why-the-best-investment-vehicle-is-one-you-can-drink" target="_blank">view source</a></span>
              </div>
              <div className="mention-item">
                  <h3>"More wines trade on the secondary market than ever before"</h3>
                  <span className="source">Liv-Ex - <a href="https://www.liv-ex.com/2021/10/wines-trade-secondary-market-ever" target="_blank">view source</a></span>
              </div>
          </div>
      </article>
      <article className="leave-us-email-container">
          <h5>Leave us your email</h5>
          <p>Be the first to receive our best wine recommendations based in recommendations of our sommeliers community.</p>
          <Form form={form} className="send-email-form-container">
            <Form.Item name="email" rules={[{ pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "This is not an email!" }]}>
              <Input placeholder="email@email.com"/>
            </Form.Item>
            <Form.Item shouldUpdate>
            {() => (
              <Button disabled={form.getFieldError('email').length > 0 || form.getFieldValue('email') == undefined}  onClick={() => subscribe()} loading={loader}>Leave us your email</Button>  
            )}
            </Form.Item>
          </Form>
      </article>
    </>
  );
}

export default App;
