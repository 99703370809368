export interface SubscribePayload {
    name: string;
    email: string;
}

export async function subscribeEmail(subscribe: SubscribePayload): Promise<boolean> {
    return await fetch(`${process.env.REACT_APP_API_URL}/subscribe`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(subscribe)
    }).then(async(response: Response) => {
        return await response.json();
    }).catch((err) => console.log(err));
}